import { UIPlugin } from '@uppy/core';
import { Provider } from '@uppy/companion-client';
import { ProviderViews } from '@uppy/provider-views';
import { h } from 'preact';
const packageJson = {
  "version": "3.1.4"
};
import locale from './locale.js';
export default class Dropbox extends UIPlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = this.opts.id || 'Dropbox';
    Provider.initPlugin(this, opts);
    this.title = this.opts.title || 'Dropbox';
    this.icon = () => h("svg", {
      className: "uppy-DashboardTab-iconDropbox",
      "aria-hidden": "true",
      focusable: "false",
      width: "32",
      height: "32",
      viewBox: "0 0 32 32"
    }, h("path", {
      d: "M10.5 7.5L5 10.955l5.5 3.454 5.5-3.454 5.5 3.454 5.5-3.454L21.5 7.5 16 10.955zM10.5 21.319L5 17.864l5.5-3.455 5.5 3.455zM16 17.864l5.5-3.455 5.5 3.455-5.5 3.455zM16 25.925l-5.5-3.455 5.5-3.454 5.5 3.454z",
      fill: "currentcolor",
      fillRule: "nonzero"
    }));
    this.provider = new Provider(uppy, {
      companionUrl: this.opts.companionUrl,
      companionHeaders: this.opts.companionHeaders,
      companionKeysParams: this.opts.companionKeysParams,
      companionCookiesRule: this.opts.companionCookiesRule,
      provider: 'dropbox',
      pluginId: this.id
    });
    this.defaultLocale = locale;
    this.i18nInit();
    this.title = this.i18n('pluginNameDropbox');
    this.onFirstRender = this.onFirstRender.bind(this);
    this.render = this.render.bind(this);
  }
  install() {
    this.view = new ProviderViews(this, {
      provider: this.provider,
      loadAllFiles: true
    });
    const {
      target
    } = this.opts;
    if (target) {
      this.mount(target, this);
    }
  }
  uninstall() {
    this.view.tearDown();
    this.unmount();
  }
  onFirstRender() {
    return Promise.all([this.provider.fetchPreAuthToken(), this.view.getFolder()]);
  }
  render(state) {
    return this.view.render(state);
  }
}
Dropbox.VERSION = packageJson.version;